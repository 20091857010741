import React from 'react'
import '../styles/company.style.css';
import PropertyMgmtHeader from '../assets/img/property_management-header.png';
import PropertyMgmt from '../assets/img/property_management.png';

export default function PropertyManagement() {
  return (
    <>
      <div class="img-container">
        <img src={PropertyMgmtHeader} alt="Property Management Hero Image" class="center-image" />
      </div>
      <div class="company-containt-container">
        <div class="company-moto-container">
          <span class="heading-text">Property Management</span>
          <span class="company-moto-text">
            There are so many things to think about with a commercial management and lease. What price do I set the
            rent? What kind of business should I tailor my property towards? All of this can be handled by a
            property management company that is well versed in commercial real estate. Whether they are restaurants,
            shops, medical office, gas stations, banks, and more, we manage it all.
            <br></br>
            What sets Maxnet Commercial apart from the rest of the pack is the care taken to ensure that tenant
            businesses succeed so that the property owners succeeds in their highest possible return on invest while
            controlling cost. Property management isn't just a safe choice, it's an investment for the future.
            <br></br>
          </span>
        </div>
        <div class="company-moto-image-container">
          <img src={PropertyMgmt} class="center-image" />
        </div>
      </div>
    </>
  )
}
