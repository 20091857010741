import React, { lazy, useState } from 'react'
import '../styles/company.style.css';
import '../styles/contact.style.css';
import ContactUsHeaderImg from '../assets/img/contact_us_header.png'
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
import axios from 'axios';
import { Bars } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';


export default function Contact() {

  const colorRed = { color: 'red' }

  const [loading, setLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [contactQueryObj, setContactQueryObj] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
    message: ''
  });

  const navigate = useNavigate();

  const onChangeContactQuery = (e) => {
    const { name, value } = e.target;
    setContactQueryObj((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    if(!captchaValue) {
      // alert('Please solve the CAPTCHA first!!!');
      Swal.fire({
        text: "Please solve the CAPTCHA first",
        // background: "#103B1D",
        confirmButtonColor: '#103B1D',
      });
      return;
    }
    // call to api
    // axios.post('https://www.maxnetcommercial.com/api/maxnet/contact', {
    //   firstName: contactQueryObj.firstName,
    //   lastName: contactQueryObj.lastName,
    //   email: contactQueryObj.email,
    //   phoneNo: contactQueryObj.phoneNo,
    //   message: contactQueryObj.message
    // }).then((response) => {
    //   Swal.fire({
    //     text: "Thanks for contacting us, we'll get back to you soon",
    //     confirmButtonColor: '#103B1D',
    //   });
    // }).catch((error) => {
    //   Swal.fire({
    //     text: "There is some error, Please try again later",
    //     confirmButtonColor: '#103B1D',
    //   });
    // })
    const response = await axios.post("https://www.maxnetcommercial.com/api/maxnet/contact",
      {
            firstName: contactQueryObj.firstName,
            lastName: contactQueryObj.lastName,
            email: contactQueryObj.email,
            phoneNo: contactQueryObj.phoneNo,
            message: contactQueryObj.message
      })

    if(response.status == 200) {
      setLoading(false);
        Swal.fire({
        text: "Thanks for contacting us, we'll get back to you soon",
        confirmButtonColor: '#103B1D',
      });
      navigate("/")
    } else {
      setLoading(false);
        Swal.fire({
        text: "There is some error, Please try again later",
        confirmButtonColor: '#103B1D',
      });
    }
  }


  return (
    <>
      <div class="img-container">
        <img src={ContactUsHeaderImg} alt="Contact Us Hero Image" class="center-image" />
      </div>
      <div class="company-containt-container">
        <div class="company-moto-container">
          <span class="heading-text">Contact Us</span>
          <span class="subheading-text">For a free Consultation</span>
          <form class="contact-form" id="contact-form" onSubmit={handleSubmit}>
            <div class="form-field-container">
              <input type="text" name="firstName" id="firstName" placeholder="First Name" class="form-field" required onChange={onChangeContactQuery}/>
              <input type="text" name="lastName" id="lastName" placeholder="Last Name" class="form-field" required onChange={onChangeContactQuery}/>
            </div>
            <div class="form-field-container">
              <span style={colorRed} id="fnameError"></span>
              <span style={colorRed} id="lnameError"></span>
            </div>
            <div class="form-field-container">
              <input type="email" name="email" id="email" placeholder="Email" class="form-field" required onChange={onChangeContactQuery}/>
              <input type="text"  name="phoneNo" id="phoneNo" placeholder="Phone No" class="form-field" required onChange={onChangeContactQuery}/>
            </div>
            <div class="form-field-container">
              <span style={colorRed} id="emailError"></span>
              <span style={colorRed} id="phoneError"></span>
            </div>
            <textarea class="form-field-1" placeholder="Message" id="message" name="message" required onChange={onChangeContactQuery}>
            </textarea>
            <div className='captcha-container' style={{margin: '18px'}}>
              <ReCAPTCHA
                sitekey="6LfgDxQqAAAAAC7n_2d22oHnRqBPNJlPh7k_l6F9"
                onChange={(token) => setCaptchaValue(token)}
              />
            </div>
            {/* <div class="form-field-container"
              style={{ border: '1px solid #103B1D', alignItems: 'center', margin: '18px', borderRadius: '10px' }}>
              <div class="form-field-tenant captcha"
                style={{ display: 'flex', flexDirection:'row', justifyContent : 'space-evenly'}}
              >
                <input readonly type="text" id="num1" name="num1" style={{width:'50px', height:'30px', textAlign :'center', marginLeft:'10px'}} /> <b style={{fontSize:'20px', marginLeft:'10px', marginRight:'10px'}}>+</b> <input readonly type="text" id="num2" name="num2" style={{ width: '50px', height: '30px', textAlign: 'center' }} /><br></br>
              </div>
              <div class="form-field-tenant captcha" style={{textAlign: 'center'}}>
                <span>*Please enter the sum of the two numbers</span>
              </div>
              <div class="form-field-tenant captcha">
                <input type="text" id="result" class="form-field" name="result" />
              </div>

            </div> */}
            { loading && <Bars height="80" width="80" color="#4fa94d" ariaLabel="bars-loading" wrapperStyle={{}} wrapperClass="" visible={true} /> }
            <input type="submit" class="contact-button" value="Submit" id="contactSubmit"/>
          </form>
        </div>
        <div class="company-moto-image-container">
          <div class="contact-data-container">
            <span style={{ fontSize: '20px' }}>Call Us </span>
            <br></br>
            <span style={{ fontSize: '16px' }}>(888) 260-8106</span>
            <br></br>
            <br></br>
            <span style={{ fontSize: '25px' }}>Email Us</span>
            <br></br>
            <span style={{ fontSize: '16px' }}>info@maxnetcommercial.com</span>
            <br></br>
            <br></br>
            <span style={{ fontSize: '25px' }}>Address</span>
            <p>
              Los Angeles Office<br></br>
              3435 Ocean Park Blvd, Suite 107-502<br></br>
              Santa Monica CA 90405
            </p>
          </div>
        </div>
      </div>
      <div class="map-container">
         <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6614.033159107254!2d-118.46214667425738!3d34.01778537246089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bb04ed055555%3A0x16f3700753d38cb1!2sMaxnet%20Commercial!5e0!3m2!1sen!2sin!4v1646768318372!5m2!1sen!2sin"
          style={{ border: '1', width: '100%', height: '600px' }} allowfullscreen="yes" loading="lazy"></iframe>  
      </div >
    </>
  )
}
