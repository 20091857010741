import React from 'react'
import '../styles/company.style.css';
import OurCompanyHeader from '../assets/img/Our-Company-header.png';
import OurCompany from '../assets/img/Our-Company.png';

export default function Company() {
  return (
    <>
      <div class="img-container">
        <img src={OurCompanyHeader} alt="Our Company Hero Image" class="center-image" />
      </div>
      <div class="company-containt-container">
        <div class="company-moto-container">
          <span class="heading-text">About Our Company</span>
          <span class="company-moto-text">
            Maxnet Commercial is a National real estate investment and asset management company with key market
            presence in the continental U.S. headquartered in Los Angeles California for our Western region
            investments and Offices in Atlanta Georgia managing the Eastern region.
            <br></br>
            Our philosophy is to be a prudent steward for our investment capital, to invest wisely, with confidence
            and intelligence in emerging, constrained markets. We seek appropriate risk adjusted returns while
            achieving income from current cash flow and appreciation through superior performance of our assets.
            <br></br>
            Maxnet Commercial is a team of real estate professionals that possess a wealth of knowledge and
            expertise in real estate, finance, investment banking, property and asset management, and complete
            construction.
            <br></br>
          </span>
        </div>
        <div class="company-moto-image-container">
          <img src={OurCompany}
            alt="Our Company" class="center-image" />
        </div>
      </div>
    </>
  )
}
