import React from 'react'
import Home from '../pages/Home'
import Company from '../pages/Company'
import PropertyManagement from '../pages/PropertyManagement'
import Contact from '../pages/Contact'
import TenantRequest from '../pages/TenantRequest'
import { Route, Routes } from 'react-router-dom'


export default function AppRoutes() {
  return (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/company" element={<Company />} />
        <Route path="/property-management" element={<PropertyManagement />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/tenant-request" element={<TenantRequest />} />
    </Routes>
  )
}
