import React, { useState } from 'react';
import '../styles/tenant.style.css';
import TenantRequestHeaderImg from '../assets/img/tenant-request.png';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
import axios from 'axios';
import ApplicationToLease from '../assets/docs/application-to-lease-form.pdf';
import AchForm from '../assets/docs/ach-form.pdf';
import { Bars } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

export default function TenantRequest() {

   const [loading, setLoading] = useState(false);
   const [captchaValue, setCaptchaValue] = useState(null);
   const [tenantFormData, setTenantFormData] = useState({
        bname: '',
        phone: '',
        fname: '',
        lname: '',
        email: '',
        priority: '',
        add1: '',
        add2: '',
        city: '',
        state: '',
        zip: '',
        message: '',
   });
   const [activeDiv, setActiveDiv] = useState(1);
   const navigate = useNavigate();

   const handleChange = (e) => {
    const {name, value} = e.target;
    setTenantFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
   }

   const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if(!captchaValue) {
        // alert('Please solve the CAPTCHA first!!!');
        Swal.fire({
            text: "Please solve the CAPTCHA first",
            // background: "#103B1D",
            confirmButtonColor: '#103B1D',
          });
          return;
      }
      // call to api    
      axios.post('https://www.maxnetcommercial.com/api/maxnet/tenant', {
        bname: tenantFormData.bname,
        phone: tenantFormData.phone,
        fname: tenantFormData.fname,
        lname: tenantFormData.lname,
        email: tenantFormData.email,
        priority: tenantFormData.priority,
        add1: tenantFormData.add1,
        add2: tenantFormData.add2,
        city: tenantFormData.city,
        state: tenantFormData.state,
        zip: tenantFormData.zip,
        message: tenantFormData.message
      }).then((response) => {
        setLoading(false);
        Swal.fire({
            text: "Thanks for contacting us, we'll get back to you soon",
            confirmButtonColor: '#103B1D',
          });
          navigate("/")
      }).catch((error) => {
        setLoading(false);
        Swal.fire({
            text: "There is some error, Please try again later",
            confirmButtonColor: '#103B1D',
          });
      })
      
   }

    return (
        <>
            <div class="img-container">
                <img src={TenantRequestHeaderImg}
                    alt="Property Management Hero" class="center-image" />
            </div>
            <p class="tenant-info-text" style={{ color: '#103B1D', textAlign: 'center', fontSize: '16px', padding: '10px' }}>Please fill the
                mainteinance form attached and we will get back to you within next 24hours
                <br></br>For emergency maintenance requests please call <b>888 260 8106</b> and we will get back to you in a timely
                manner.
            </p>
            <div class="tenant-container">

                <div class="request-parent-container">
                    <ul class="request-button-container">
                        <li class="request-button" onClick={() => setActiveDiv(1) }>Maintainance Request</li>
                        <li class="request-button" onClick={() => setActiveDiv(2) }>Tenant Form</li>
                        <li class="request-button" onClick={() => setActiveDiv(3) }>ACH Form</li>
                    </ul>  
                </div>
                <div class="various-form-container">
                    <div class="tabcontent" id="maintainance" className={activeDiv == 1 ? 'display-block' : 'display-none'}>
                        <span class="heading" style={{ textAlign: 'center', display: 'block', color: '#103B1D' }}>Maintainance
                            Request Form</span>
                        <form class="contact-form" id="contact-form" onSubmit={handleSubmit}>
                            <div class="form-field-container">
                                <input type="text" name="bname" id="bname" placeholder="Business Name" class="form-field" required onChange={handleChange}/>
                                <input type="text" name="phone" id="phone" placeholder="Phone No" class="form-field" required onChange={handleChange}/>
                            </div>
                            <div class="form-field-container">
                                <input type="text" name="fname" id="fname" placeholder="First Name" class="form-field" required onChange={handleChange}/>
                                <input type="text" name="lname" id="lname" placeholder="Last Name" class="form-field" required onChange={handleChange}/>
                            </div>
                            <div class="form-field-container">
                                <input type="email" name="email" id="email" placeholder="Email" class="form-field" required onChange={handleChange} />
                                <select 
                                    name="priority" 
                                    id="priority" 
                                    class="form-field" 
                                    required
                                    value={tenantFormData.priority}
                                    onChange={handleChange}
                                >
                                    <option value="">Priority</option>
                                    <option value="Urgent">Urgent</option>
                                    <option value="High Priority">High Priority</option>
                                    <option value="Medium Priority">Medium Priority</option>
                                    <option value="Low Priority">Low Priority</option>
                                </select>
                            </div>
                            <div class="form-field-container">
                                <input type="text" name="add1" id="add1" placeholder="Address 1" class="form-field" required onChange={handleChange}/>
                                <input type="text" name="add2" id="add2" placeholder="Address 2" class="form-field" onChange={handleChange}/>
                            </div>
                            <div class="form-field-container">
                                <input type="text" name="city" id="city" placeholder="City" class="form-field" required onChange={handleChange}/>
                                <input type="text" name="state" id="state" placeholder="State" class="form-field" required onChange={handleChange}/>
                            </div>
                            <div class="form-field-tenant">
                                <input type="numeric" name="zip" id="zip" placeholder="Zip" class="form-field" required onChange={handleChange}/>
                            </div>
                            <textarea class="form-field-1" placeholder="Message" id="message" name="message" required onChange={handleChange}>
                            </textarea>
                            { loading && <Bars height="80" width="80" color="#4fa94d" ariaLabel="bars-loading" wrapperStyle={{}} wrapperClass="" visible={true} /> }
                            <div className='captcha-container' style={{ margin: '18px' }}>
                                <ReCAPTCHA
                                    sitekey="6LfgDxQqAAAAAC7n_2d22oHnRqBPNJlPh7k_l6F9"
                                    onChange={(token) => { setCaptchaValue(token) }}
                                />
                            </div>
                            {/* <div class="form-field-container"
                style={{border: '1px solid #103B1D', alignItems: 'center', margin: '18px', borderRadius: '10px'}}>
                <div class="form-field-tenant captcha">
                   <input type="text" readonly id="num1" name="num1" style={{width:'50px', height: '30px', textAlign:'center'}}/> <b style={{fontSize:'20px', marginLeft:'10px', marginRight:'10px'}}>+</b> <input readonly type="text" id="num2" name="num2" style={{width:'50px', height: '30px', textAlign:'center'}}/><br></br>
                </div>
                <div class="form-field-tenant captcha">
                    <span>*Please enter the sum of the two numbers</span>
                </div>
                <div class="form-field-tenant captcha">
                    <input type="text" id="result" class="form-field" name="result"/>
                </div>

            </div> */}
                            <input type="submit" class="contact-button" value="Submit" id="contactSubmit" name="contactSubmit" />
                        </form>
                    </div>

                    <div class="tabcontent" id="tenant" className={activeDiv == 2 ? 'display-block' : 'display-none'}>
                        <span class="heading" style={{ textAlign: "center", display: "block", color: "#103B1D" }}>Tenant
                            Form</span>
                        <span class="tenant-request-text">Kindly download New Tanent Request Form from given link and send us at
                            info@maxnetcommercial.com</span>
                        <div class="tenant-forms-container">
                            <span class="tenant-request-text" style={{ display: 'block' }}>Application to lease Form</span>
                            <a href={ApplicationToLease} download="application-to-lease"
                                class="tenant-request-button">Download Form</a>
                        </div>
                    </div>

                    <div class="tabcontent" id="ACH" className={activeDiv == 3 ? 'display-block' : 'display-none'}>
                        <span class="heading" style={{ textAlign: 'center', display: "block", color: "#103B1D" }}>ACH
                            Form</span>
                        <span class="tenant-request-text">Kindly download ACH Form from given link and send us at
                            info@maxnetcommercial.com</span>
                        <div class="tenant-forms-container">
                            <span class="tenant-request-text" style={{ display: 'block' }}>ACH Form</span>
                            <a href={AchForm} download="ach-form" class="tenant-request-button">Download
                                Form</a>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
