import React from 'react'
import '../styles/navbar.style.css';
import Logo from '../assets/img/logo.jpg'
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <>
        <div className='header'>
        <nav class="navbar">
            <div class="logo-container">
                <Link to="/" >
                    <img src={Logo} alt="MaxNet Commercial Logo" class="logo"/>
                </Link>
            </div>
            <ul class="nav-links">
                <input type="checkbox" id="checkbox_toggle" />
                <label for="checkbox_toggle" class="hamburger">&#9776;</label>
                <div class="menu">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/company">Our Company</Link></li>
                    <li><Link to="/property-management">Property
                            Management</Link></li>
                    <li><Link to="/contact">Contact Us</Link></li>
                    <li><Link to="/tenant-request"><span
                                class="tenant-button">Tenant Request</span></Link></li>
                </div>
            </ul>
        </nav>
        </div>
    </>
  )
}
