import React from 'react'
import '../styles/containt.style.css';
import HomePageImage from '../assets/img/home-page-img.png'

export default function Home() {
  return (
    <>
        <div class="img-container">
        <img src={HomePageImage} alt="Home Page Hero Image" class="home-img" style={{height: '100%', width: '100%'}} />
    </div>
      <div class="containt-container">
        <div class="experties-parent-container">
          <span class="heading-text">Areas Of Experties</span>
          <ul class="experties-container">
            <li class="experties">Property Management</li>
            <li class="experties">Tenant Improvements</li>
            <li class="experties">Leasing</li>
            <li class="experties">Nationwide Presence</li>
            <li class="experties">Retail and Multi Family</li>
            <li class="experties">Licenced General Contractors</li>
          </ul>
        </div>
      </div>
    </>
  )
}
