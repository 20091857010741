import React from 'react'
import '../styles/footer.style.css';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <>
            <div class="footer-container">
                <div class="footer-left-container">
                    <span class="footer-heading">MAXNET Commercial</span>
                    <br></br>

                    <span class="footer-subheading">Property Development and Management</span>
                    <br></br>
                    <br></br>
                    <address className='footer-address'>
                        Los Angeles Office
                        Maxnet Commercial3435 Ocean Park Blvd, Suite 107-502
                        Santa Monica CA 90405
                    </address>
                </div>
                <div class="footer-center-container">
                    <ul class="footer-menu">

                        <li>
                            <Link to="/">Home</Link>
                        </li>

                        <li>
                            <Link to="/company">Our Company</Link>
                        </li>

                        <li>
                            <Link to="/property-management">Property
                                Management</Link>
                        </li>

                        <li>
                            <Link to="/contact">Contact Us</Link>
                        </li>

                        <li>
                            <Link to="/tenant-request">Tenant Request</Link>
                        </li>
                    </ul>
                </div>
                <div class="footer-right-container">
                    <Link to="/tenant-request"><span style={{ fontSize: '20px', color: 'white' }}>Tenant Request</span></Link>
                    <br></br>
                    <br></br>
                    <span style={{ fontSize: '20px' }}>Call Us </span>
                    <br></br>
                    <br></br>
                    <span style={{ fontSize: '16px', color: 'white' }}>(888) 260-8106</span>
                    <br></br>
                    <br></br>
                    <span style={{ fontSize: '20px' }}>Email Us</span>
                    <br></br>
                    <span style={{ fontSize: '16px', color: 'white' }}>info@maxnetcommercial.com</span>
                    <br></br>
                    <br></br>
                </div>
            </div>
            <div class="promotion-container">
                <span>Site Developed By HighQ Solutions</span>
            </div>
        </>
    )
}
